<script>
import { layoutMethods } from "@/state/helpers";
// import { menuItems } from "./horizontal-menu";
import axios from "axios";

export default {
    data() {
        return {
            menuItems: [],
            // menuItems: [
            //         {
            //             link: "/",
            //             icon: "",
            //             label: "Beranda",
            //         },
			// 		{
            //             link: "/website/profil",
            //             icon: "",
            //             label: "Profil",
            //         },
			// 		{
            //             link: "/website/skema-sertifikasi",
            //             icon: "",
            //             label: "Skema Sertifikasi",
            //         },
			// 		{
            //             link: "/website/informasi",
            //             icon: "",
            //             label: "Informasi",
            //         },
			// 		{
            //             link: "/website/galeri",
            //             icon: "",
            //             label: "Galeri",
            //         },
			// 		{
            //             link: "/website/kontak",
            //             icon: "",
            //             label: "Kontak",
            //         },
			// 		{
            //             link: "/website/pengaduan",
            //             icon: "",
            //             label: "Pengaduan",
            //         },
			// 		{
            //             link: "/website/faq",
            //             icon: "",
            //             label: "FAQ",
            //         },
            //     ],
        };
    },
    mounted() {
        let self = this;
        // get data halaman berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/menu-master-website",
            params: {

            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.menuItems = response_data_fix.referensi;
                } else {
                console.log('error');
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        for (var i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i].pathname) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            var parent = matchingMenuItem.parentElement;
            
            
            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */

            if (parent) {
                parent.classList.add("active");
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add("active");
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add("active");
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            parent4.classList.add("active");
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.add("active");
                                const parent6 = parent5.parentElement;
                                if (parent6) {
                                    parent6.classList.add("active");
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        ...layoutMethods,
        /**
         * Menu clicked show the submenu
         */
        onMenuClick(event) {
            event.preventDefault();
            const nextEl = event.target.nextSibling;
            if (nextEl && !nextEl.classList.contains("show")) {
                const parentEl = event.target.parentNode;
                if (parentEl) {
                    parentEl.classList.remove("show");
                }
                nextEl.classList.add("show");
            } else if (nextEl) {
                nextEl.classList.remove("show");
            }
            return false;
        },
        isMenuItemActive(item) {
            return this.$route.path === item.menu_master_link_path;
        },
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false;
        },
    },
};
</script>
<template>
    <div class="topnav">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
                <div class="collapse navbar-collapse active" id="topnav-menu-content">
                    <ul class="navbar-nav mx-auto">
                        <!-- Menu data -->
                        <template v-for="(item, index) of menuItems" :key="index">
                            <li class="nav-item dropdown">
                                <router-link
                                    class="nav-link"
                                    :class="{ 'bg-info': isMenuItemActive(item) }"
                                    :to="item.menu_master_link_path"
                                    role="button"
                                    >
                                    <i :class="`bx ${item.menu_master_icon} me-2`"></i>{{ $t(item.menu_master_nama) }}
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>
<style scoped>
.nav-link.bg-info {
  background-color: #17a2b8; /* Change this to the desired background color */
  color: #fff; /* Change the text color for better contrast */
}
</style>