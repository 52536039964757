<script>
export default {
  data() {
    return {
      configuration: {
        COPYRIGHT_COPORATION: 'LSP Bio Farma',
        VERSION: '1.0'
      },
        namaApp : "LSP Holding BUMN Farmasi",
        alamat : `Gedung Publik 2 lantai 2 PT. Bio Farma (Persero) 
                  Jl. Pasteur No. 28, Kota Bandung, Indonesia 40161`,
        phone : "022-2033755 (belum punya no hp resmi)",
        email : "lspbumnholdingfarma@biofarma.co.id",
    };
  },
};
</script>
<template>
  <footer class="footer pt-5 px-0 text-white pb-0" style="position: relative; height: unset; background-color: #98bde2;">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <img src="@/assets/images/logo_lsp_biofarma.png" style="width: 100px; padding-right:10px;">
          <b class="text-white" style="font-size: large;"> LSP Holding BUMN Farmasi</b><br>
          <br>
          <b class="text-white" style="font-size: medium;">The Leading Vaccine Manufacturer in Indonesia</b>
          <!-- <p class="text-white mt-3">
            Jl. Pasteur No.28, Pasteur, Kec. Sukajadi, Kota Bandung, Jawa Barat 40161
          </p> -->
        </div>
        <div class="col-md-4">
          <h3 class="text-white">Head Office:</h3>
          <section class="mb-4">
            <ul class="list-inline">
              <li>
                <div class="row d-flex flex-row" id="example text" role="group ">
                  <div class="col-1 text-center">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                    <div class="col">
                      {{ alamat }}
                    </div>
                </div>
              </li>
              <li class="mt-2">
                <div class="row d-flex flex-row" id="example text" role="group ">
                  <div class="col-1 text-center">
                    <i class="fas fa-phone"></i>
                  </div>
                    <div class="col">
                        {{ phone }}                      
                    </div>
                  </div>
              </li>
              <li class="mt-2">
                <div class="row d-flex flex-row" id="example text" role="group ">
                  <div class="col-1 text-center">
                    <i class="fas fa-envelope"></i>
                  </div>
                    <div class="col">
                        {{ email }}                      
                    </div>
                  </div>
              </li>
            </ul>
          </section>
          <!-- <h3 class="text-white">Breeding Facility Bio Farma:</h3>
          <section class="mb-4">
            <ul class="list-inline">
              <li>
                <a class="text-white" href="#">
                  <i class="fas fa-map-marker-alt"></i> Jl. Kolonel Masturi Kav 10. Desa Kertawangi kec. Cisarua Jawa Barat
                </a>
              </li>
            </ul>
          </section> -->
        </div>
        <div class="col-md">
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="@/assets/images/logo-biofarma1.png" class="d-block w-100">
            </div>
            <div class="col-md-6 mb-3">
              <img src="@/assets/images/logo-akhlak.png" class="d-block w-100">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="container-fluid py-3" style="background-color: #98bde2;">
      <div class="row">
        <div class="col-sm-12 text-center" style="font-weight: bold;">
          {{ new Date().getFullYear() }} ©
          {{ configuration.COPYRIGHT_COPORATION }}.
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
