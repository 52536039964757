<script>

import { layoutComputed } from "@/state/helpers";

/**
 * Horizontal-topbar component
 */
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  components: {
    
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
    };
  },
  
  
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex" style="padding-left: 80px;">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/website/logo_lsp_biofarma.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/website/logo_lsp_biofarma.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/website/logo_lsp_biofarma.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/website/logo_lsp_biofarma.png" alt height="40" />
            </span>
            <span class="logo-lg" style="margin-left: 20px;">
              <img src="/website/logo-biofarma1.png" alt height="50" />
            </span>
          </router-link>
        </div>

        
        
      </div>

      <div class="d-flex" style="padding-right: 80px;">
        <router-link :to="{name:'register'}" class="btn btn-sm btn-success m-1"><i class='bx bx-log-in-circle' ></i> Registrasi </router-link>
        <router-link :to="{name:'login'}" class="btn btn-sm btn-info m-1"><i class='bx bx-log-in-circle' ></i> Masuk </router-link>
      </div>
    </div>
  </header>
</template>
