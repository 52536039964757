<script>
// import axios from "axios";
// import Swal from "sweetalert2";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import { createApp } from 'vue'
import App from '@/App.vue'

createApp(App).use(VueReCaptcha, { siteKey: '6LekLIgnAAAAADgz3m6INc8CNHP8F7pMNxu_Q5Qp' })
/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    // PageHeader,
  },
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style>
  .grecaptcha-badge{
    visibility: visible;
  }
</style>
<template>
  <Layout>
    <div>
      <div class="container-fluid p-0">
        <div class="row g-0 bg-white">
          <div class="col-xl-3"></div>
          <div class="col-xl-6">
            <div class="auth-full-page-content p-md-5 p-4">
              <div class="w-100">
                <div class="d-flex flex-column h-100">
                  <div class="my-auto">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h1 class="text-center">Formulir Registrasi</h1>
                        </div>

                        <div class="mt-4">
                          <b-form @submit.prevent="tryToLogIn">
                            <div class="row">
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">NIK (Nomor Induk Karyawan)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Nama Lengkap</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">No.KTP/Paspor</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Tempat / Tanggal Lahir</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Jenis Kelamin</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Jabatan</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Alamat</label>
                                <div class="col-sm-9">
                                  <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Kode POS</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">No.HP</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label">Email</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control">
                                </div>
                              </div>
                              <div class="col-lg-12 row">
                                <div class="text-end">
                                  <router-link :to="{ name: 'login' }" class="btn btn-primary"> Submit</router-link>
                                </div>
                              </div>
                            </div>
                          </b-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
  </Layout>
</template>
